import React from "react";
import { Stack } from "../common";
import { ZHelmet } from "../components/ZHelmet";
import { Clock } from "../common/icons/ClockIcon";
import { Money } from "../common/icons/MoneyIcon";
import { Repayment } from "../common/icons/RepaymentIcon";
import { Bell } from "../common/icons/BellIcon";
import { CityList } from "../components/CityList";
import { CityMobileLayout } from "../components/CityMobileLayout";
import { CityBeforeApplyLoan } from "../components/CityBeforeApplyLoan";
import { CityWhyUs } from "../components/CityWhyUs";
import { CityProducts } from "../components/CityProducts";
import { CityLanding } from "../components/CityLanding";
import { EligibilityCriteria } from "../components/CityEligibilityCriteria";

const Features = [
  {
    icon: <Clock />,
    title: "Apply Instantly",
    content:
      "Applying for a Personal Loan is easy with Zavron. Just fill the form and we’ll direct you forward.",
  },
  {
    icon: <Money />,
    title: "High Disbursal Amount",
    content:
      "If you fit into the eligibility criteria, then you can apply for a loan of upto 10 lacs.",
  },
  {
    icon: <Repayment />,
    title: "Easy Repayment",
    content:
      "Our flexible repayment options and competitive interest rates help you repay loans within a period of 60 months.",
  },
  {
    icon: <Bell />,
    title: "Easy Documentation",
    content:
      "We assist you with Personal Loans with easy and hassle-free documentations.",
  },
];

const Easy_Steps = [
  {
    title: "Sign Up your accounts",
    content: [
      `You have to fill minimal details about you and your professoin to create an account.`,
    ],
  },
  {
    title: "Fill Form Details",
    content: [
      `Filling form is very easy just keep typing the details as guided by App`,
    ],
  },
  {
    title: "Get Instant Personal Loan",
    content: [
      `Once you finished you may get an instant personal loan as per your eligibility.`,
    ],
  },
];

const befor_Apply = [
  {
    title: "",
    content: ["Copy of PAN"],
  },

  {
    title: "",
    content: [
      "Last 4 Months Salary Account s Bank Statement. Please that your statement should be bank generated and in PDF format only.",
    ],
  },

  {
    title: "",
    content: ["Aadhar / Driving Licence / Passport / Voter ID (Any one)"],
  },
];

export default function Personal_loan_in_gurgaon() {
  return (
    <CityMobileLayout>
      <ZHelmet
        title="Personal Loan - Gurgaon | ZinCash| Loan App | Download Now"
        description="Apply now to get instant personal loan in Gurgaon upto 2 lakh from ZinCash quick loan app by Zavron Finserv download now best loan App for urgent loans"
        keywords="Personal Loan App, Instant Personal Loan App, Mobile Loan App, Quick Loan App, ZinCash Loan App, Download Personal Loan App, Lending App, Get Loan App, Best Loan App,"
      />
      <Stack space="var(--s3)">
        <CityLanding
          title="Personal Loan in Gurgaon"
          city_image={
            "https://res.cloudinary.com/dhoiqmk4x/image/upload/v1631685040/Website/City/personal-loan-in-gurgaon_zhyr5j.png"
          }
          sub_title="What's up Gurgaon? Oh, Wait! Gurugram"
          description={[
            "The village famous for  Guru Dronacharya  transformed  drastically into a technological  and financial  hub, Gurgaon or Gurugram. The city is the IT hub of North India and a hotspot for Fortune 500 firms.",
            "With a population so much into  finance  and  technology,  the financial requirements should be met to the 'T.' To bring financial solutions quickly at their convenience, Zavron Finserv has come up with ZenCash Loan App to avail instant personal loans at modest interest rates, quickly without any hassle.",
          ]}
        />

        <CityProducts easy_steps={Easy_Steps} />

        <CityWhyUs features={Features} />

        <EligibilityCriteria />

        <CityBeforeApplyLoan
          subtitle="We recommend you to keep following documents handy before you apply for an Instant Persolan loan through ZinCash"
          befor_Apply={befor_Apply}
        />

        <CityList />
      </Stack>
    </CityMobileLayout>
  );
}
